import axios from "axios";
import {notify} from "@kyvg/vue3-notification";

export default {
    namespaced: true,

    state: () => ({
        company: null,
        companies: null,
        userCompany: null,
        quota: {
            course: {
                free: null,
                issued: null
            },
            test: {
                free: null,
                issued: null
            },
            trajectories: {
                free: null,
                issued: null
            },

        },
        userQuotas: null,
        analyticsAllowedAccessUsers: null,
        analyticsAccessCompanies: [],
        selectedCompany: null,
    }),

    mutations: {
        setCompany(state, data) {
            state.company = data
        },
        setCompanies(state, data) {
            state.companies = data
        },
        setUserCompany(state, data) {
            state.userCompany = data
        },
        setQuota(state, data) {
            state.quota = data
        },
        setSelectedCompany(state, data) {
            state.selectedCompany = data
        },
        setAnalyticsAllowedAccessUsers(state, data) {
            state.analyticsAllowedAccessUsers = data
        },
        async setAnalyticsAccessCompanies(state, data) {
            state.analyticsAccessCompanies = data;
        },
        giveGroupPassings(state, data) {
            let testIssued = state.quota.test.issued.find((test) => test.report_type === data.reportType)
            let testsFree = state.quota.test.free.map((test) => {
                if (test.report_type !== data.reportType) return test
                else return {...test, count: test.count - data.count}
            }), testsIssued = null;
            if (testIssued) testsIssued = state.quota.test.issued.map((test) => {
                if (test.report_type !== data.reportType) return test
                else return {...test, count: +test.count + +data.count}
            })
            else testsIssued = [...state.quota.test.issued, {report_type: data.reportType, count: data.count}]
            state.quota = {...state.quota, test: {free: testsFree, issued: testsIssued}};
        },
        takeGroupPassings(state, data) {
            let testFree = state.quota.test.free.find((test) => test.report_type === data.reportType)
            let testsFree = null, testsIssued = state.quota.test.issued.map((test) => {
                if (test.report_type !== data.reportType) return test
                else return {...test, count: test.count - data.count}
            })
            if (testFree) testsFree = state.quota.test.free.map((test) => {
                if (test.report_type !== data.reportType) return test
                else return {...test, count: +test.count + +data.count}
            })
            else testsFree = [...state.quota.test.free, {report_type: data.reportType, count: data.count}]
            state.quota = {...state.quota, test: {free: testsFree, issued: testsIssued}};
        },
        giveGroupCourses(state, data) {
            let courseIssued = state.quota.course.issued.find((course) => course.external_id === data.courseCode)
            let coursesFree = state.quota.course.free.map((course) => {
                if (course.external_id !== data.courseCode) return course;
                else return {...course, count: course.count - data.count}
            }), coursesIssued = null;
            if (courseIssued) coursesIssued = state.quota.course.issued.map((course) => {
                if (course.external_id !== data.courseCode) return course;
                else return {...course, count: +course.count + +data.count}
            })
            else coursesIssued = [...state.quota.course.issued, {external_id: data.courseCode, count: data.count}]
            state.quota = {...state.quota, course: {free: coursesFree, issued: coursesIssued}};
        },
        takeGroupCourses(state, data) {
            let courseFree = state.quota.course.free.find((course) => course.external_id === data.courseCode)
            let coursesFree = null, coursesIssued = state.quota.course.issued.map((course) => {
                if (course.external_id !== data.courseCode) return course;
                else return {...course, count: course.count - data.count}
            })
            if (courseFree) coursesFree = state.quota.course.free.map((course) => {
                if (course.external_id !== data.courseCode) return course;
                else return {...course, count: +course.count + +data.count}
            })
            else coursesFree = [...state.quota.course.free, {external_id: data.courseCode, count: data.count}]
            state.quota = {...state.quota, course: {free: coursesFree, issued: coursesIssued}};
        },
        giveGroupTrajectories(state, data) {
            let trajectoriesFree = state.quota.trajectories.free.map((trajectories) => {
                return {...trajectories, count: trajectories.count - data.count}
            });
            let trajectoriesIssued = state.quota.trajectories.issued.map((trajectories) => {
                return {...trajectories, count: +trajectories.count + +data.count}
            })
            state.quota = {...state.quota, trajectories: {free: trajectoriesFree, issued: trajectoriesIssued}};
        },
        takeGroupTrajectories(state, data) {
            let trajectoriesIssued = state.quota.trajectories.issued.map((trajectories) => {
                return {...trajectories, count: trajectories.count - data.count}
            })
            let trajectoriesFree = state.quota.trajectories.free.map((trajectories) => {
                return {...trajectories, count: +trajectories.count + +data.count}
            })
            state.quota = {...state.quota, trajectories: {free: trajectoriesFree, issued: trajectoriesIssued}};
        },
        setUserQuotas(state, data) {
            state.userQuotas = data;
        }
    },

    actions: {
        // eslint-disable-next-line no-unused-vars
        async createCompany({commit}, data) {
            await axios.post(`/companies`, data).then(() => {
                notify({
                    title: "Данные сохранены",
                    type: "success"
                })
            })
        },
        async getCompany({commit}, id) {
            await axios.get(`/companies/${id}`)
                .then(({data}) => {
                    commit("setCompany", data)
                })

        },
        async getCompanies({commit}) {
            await axios.get(`/companies`)
                .then(({data}) => {
                    commit("setCompanies", data)
                })
        },
        async getUserCompany({commit}) {
            await axios.get(`/companies/current`)
                .then(({data}) => {
                    commit("setUserCompany", data)
                })
        },
        async getCompanyQuota({commit}, id) {
            await axios.get(`/companies/${id}/free_passings`)
                .then(({data}) => {
                    commit("setQuota", data)
                })
        },
        async updateCompany({commit}, data) {
            await axios.put(`/companies/${data.id}`, data.company)
                .then(({data}) => {
                    commit("setCompany", data)
                    notify({
                        title: "Данные сохранены",
                        type: "success"
                    })
                })
        },
        async giveGroupPassings({commit}, data) {
            await axios.post(`/groups/${data.id}/passing/test/${data.reportType}/quota/${data.count}`)
                .then(() => {
                    commit("giveGroupPassings", data)
                    notify({
                        type: "success",
                        title: "Операция прошла успешно"
                    })
                })
        },
        async takeGroupPassings({commit}, data) {
            await axios.delete(`/groups/${data.id}/passing/test/${data.reportType}/quota/${data.count}`)
                .then(() => {
                    commit("takeGroupPassings", data)
                    notify({
                        type: "success",
                        title: "Операция прошла успешно"
                    })
                })
        },
        async giveGroupCourses({commit}, data) {
            await axios.post(`/groups/${data.id}/passing/course/${data.courseCode}/quota/${data.count}`)
                .then(() => {
                    commit("giveGroupCourses", data)
                    notify({
                        type: "success",
                        title: "Операция прошла успешно"
                    })
                })
        },
        async takeGroupCourses({commit}, data) {
            await axios.delete(`/groups/${data.id}/passing/course/${data.courseCode}/quota/${data.count}`)
                .then(() => {
                    commit("takeGroupCourses", data)
                    notify({
                        type: "success",
                        title: "Операция прошла успешно"
                    })
                })
        },
        async giveGroupTrajectories({commit}, data) {
            await axios.post(`/groups/${data.id}/passing/trajectories/quota/${data.count}`)
                .then(() => {
                    commit("giveGroupTrajectories", data)
                    notify({
                        type: "success",
                        title: "Операция прошла успешно"
                    })
                })
        },
        async takeGroupTrajectories({commit}, data) {
            await axios.delete(`/groups/${data.id}/passing/trajectories/quota/${data.count}`)
                .then(() => {
                    commit("takeGroupTrajectories", data)
                    notify({
                        type: "success",
                        title: "Операция прошла успешно"
                    })
                })
        },
        async getUserQuotas({commit}) {
            await axios.get(`companies/quotas`)
                .then(({data}) => {
                    commit("setUserQuotas", data)
                })
        },
        async setAnalyticsAccessUsers({commit}, data) {
            await axios.post(`companies/${data.id}/set_analytics_access/`, {
                users: data.users,
            })
                .then(({data}) => {
                    commit("setAnalyticsAllowedAccessUsers", data)
                })
        },
        async getAnalyticsAllowedCompanies({ commit }) {
            await axios.get(  `companies/permitted_analytics_companies`).then(({ data }) => {
                commit("setAnalyticsAccessCompanies", data)
            })
        },
        async getAnalyticsAccessUsers({commit}, id) {
            await axios.get(`companies/${id}/permitted_analytics_users`)
                .then(({data}) => {
                    commit("setAnalyticsAllowedAccessUsers", data)
                })
        },
    }
}
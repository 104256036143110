<template>
  <div
      v-if="showXS"
      class="xs-menu position--absolute display--flex align-item-center"
  >
    <div
        @click="tabletBtn = !tabletBtn"
        class="mobile-btn mobile-btn__xs display--flex align-item-center justify-content-center"
    >
      <div class="mobile-btn__icon"></div>
    </div>
    <a :href="siteUrl" class="brand display--flex align-item-center">
      <img src="@/assets/images/logo.svg" alt=""/>
    </a>
  </div>
  <div v-if="tabletBtn" class="overlay" @click="tabletBtn = !tabletBtn"></div>
  <aside :class="{ aside__small: showSmallMenu, aside__xs: showXS, open: showXS && tabletBtn }">
    <div
        v-if="!showXS || (showXS && tabletBtn)"
        class="display--flex align-item-center aside-header"
    >
      <div
          @click="tabletBtn = !tabletBtn"
          class=" mobile-btn display--flex align-item-center justify-content-center "
      >
        <div class="mobile-btn__icon"></div>
      </div>
      <a
          class="brand display--flex align-item-center"
          href="https://way2wei.ru/"
      >
        <img src="@/assets/images/logo-b.svg" alt=""/>
      </a>
    </div>
    <div class="menu-container">
      <div
          class="navbar-sec display--flex flex-column"
          :class="{ 'navbar-sec__small': showSmallMenu }"
      >
        <router-link class="navbar-sec__link complex-link" to="/">
          <div class="navbar-sec__link-container">
            <i class="fad fa-cube"></i>
            <span class="navbar-sec__link-name">Главная</span>
          </div>
        </router-link>
        <!--Меню для простого юзера-->
        <ul v-if="getRight('staff') || getRight('private_person')" class="display--flex flex-column">
          <li v-for="(test, index) in testPassings" :key="index"
              @click="getReportLink(test)"
              class="navbar-sec__link complex-link">
            <div v-if="test.count != 0" class="navbar-sec__link-container">
              <img src="@/assets/images/icons/report.svg" alt=""/>
              <div class="navbar-sec__link-block">
                <span class="navbar-sec__link-name">{{ test.title }}</span>
                <span>{{ test.count }}</span>
              </div>
            </div>
          </li>
          <li v-for="course of coursePassings" :key="course.course_id"
              @click="getCourseLink(course.course_id)"
              class="navbar-sec__link complex-link">
            <div class="navbar-sec__link-container">
              <img src="@/assets/images/icons/course.svg" alt=""/>
              <div class="navbar-sec__link-block">
                <span class="navbar-sec__link-name">{{ course.name }}</span>
                <span>{{ course.count }}</span>
              </div>
            </div>
          </li>
          <li v-if="getRight('private_person')">
            <div
                class="navbar-sec__link complex-link"
                :class="{ open: openLinkGroup == names.NEWPRODUCT }"
                @click="clickOnMenuItem(names.NEWPRODUCT)"
            >
              <div class="navbar-sec__link-container" @click="showConsultModal">
                <span class="navbar-sec__link-name">+ заказать новый продукт</span>
              </div>
            </div>
          </li>
        </ul>

        <!--Меню для директора-->
        <ul v-else class="display--flex flex-column">
          <!--тесты-->
          <li v-for="(test, index) in testPassings" :key="index"
              :class="{ open: openLinkGroup == test.name }"
              class="navbar-sec__link complex-link">
              <router-link
                v-if="test.count !== '0'"
                :to="`/${test.name}/all`"
                class="navbar-sec__link-container"
                @click="clickOnMenuItem(test.name)">

                <img src="@/assets/images/icons/report.svg" alt=""/>
                <div class="navbar-sec__link-block">
                  <span class="navbar-sec__link-name">{{ test.title }}</span>
                  <span>{{ test.count }}</span>
                </div>
              </router-link>
            <transition name="slide-down">
              <ul
                  v-if="openSmallLinkGroup === test.name && !showSmallMenu"
                  class="navbar-sec__link-group display--flex flex-column"
              >
                <li>
                  <router-link
                      :to="`/${test.name}/all`"
                      :class="{ active: selectSmallLinkGroup == 'all' }"
                  >
                    <div class="navbar-sec__link-container">
                      <span class="navbar-sec__link-subgroup-name">Все сотрудники</span>
                    </div>
                  </router-link>
                </li>
                <li v-for="group of groups" :key="group.id">
                  <router-link
                      :to="`/${test.name}/${group.id}`"
                      :class="{ active: selectSmallLinkGroup == group.id }"
                  >
                    <div class="navbar-sec__link-container">
                      <img src="@/assets/images/icons/dropdownArrow.svg" alt=""/>
                      <span class="navbar-sec__link-subgroup-name">{{ group.name }}</span>
                    </div>
                  </router-link>
                </li>
                <li v-if="getRight('addGroup')">
                  <router-link
                      to="/group/edit"
                      :class="{ active: selectSmallLinkGroup == names.NEWGROUP }"
                  >
                    <div class="navbar-sec__link-container">
                      <span class="navbar-sec__link-subgroup-name">+ группа</span>
                    </div>
                  </router-link>
                </li>
              </ul>
            </transition>
          </li>
          <!--курсы-->
          <li v-for="course of coursePassings" :key="course.course_id"
              class="navbar-sec__link complex-link"
              :class="{ open: openLinkGroup == course.course_id}">
            <router-link
              class="navbar-sec__link-container"
              :to="`/${course.course_id}/all`"
              @click="clickOnMenuItem(course.course_id)">

              <img src="@/assets/images/icons/course.svg" alt=""/>
              <div class="navbar-sec__link-block">
                <span class="navbar-sec__link-name course-link" :data-tooltip="course.name">{{ course.name }}</span>
                <span>{{ course.count }}</span>
              </div>
            </router-link>

            <transition name="slide-down">
              <ul
                  v-if="openSmallLinkGroup === course.course_id && !showSmallMenu"
                  class="navbar-sec__link-group display--flex flex-column"
              >
                <li>
                  <router-link
                      :to="`/${course.course_id}/all`"
                      :class="{ active: selectSmallLinkGroup == 'all' }"
                  >
                    <div class="navbar-sec__link-container">
                      <span class="navbar-sec__link-subgroup-name">Все сотрудники</span>
                    </div>
                  </router-link>
                </li>
                <li v-for="group of groups" :key="group.id">
                  <router-link
                      :to="{ name: 'product-report',
                             params: {product: course.course_id, id: group.id, nameProduct: course.name},
                      }"
                      :class="{ active: selectSmallLinkGroup == group.id }"
                  >
                    <div class="navbar-sec__link-container">
                      <img src="@/assets/images/icons/dropdownArrow.svg" alt=""/>
                      <span class="navbar-sec__link-subgroup-name">{{ group.name }}</span>
                    </div>
                  </router-link>
                </li>
                <li v-if="getRight('addGroup')">
                  <router-link
                      to="/group/edit"
                      :class="{ active: selectSmallLinkGroup == names.NEWGROUP }"
                  >
                    <div class="navbar-sec__link-container">
                      <span class="navbar-sec__link-subgroup-name">+ группа</span>
                    </div>
                  </router-link>
                </li>
              </ul>
            </transition>
          </li>
          <!--траектории-->
          <li class="navbar-sec__link complex-link"
              :class="{ open: openLinkGroup == names.TRAJECTORIES }"
              @click="clickOnMenuItem(names.TRAJECTORIES)">
            <div v-if="trajectoriesCount" class="navbar-sec__link-container">
              <img src="@/assets/images/icons/references.svg" alt=""/>
              <div class="navbar-sec__link-block">
                <div>
                  <p class="navbar-sec__link-name">Траектории</p>
                </div>
                <span>{{ trajectoriesCount }}</span>
              </div>
            </div>
            <transition name="slide-down">
              <ul
                  v-if="openSmallLinkGroup === names.TRAJECTORIES && !showSmallMenu"
                  class="navbar-sec__link-group display--flex flex-column"
              >
                <li v-for="group of groups" :key="group.id">
                  <router-link
                      :to="`/trajectory/${group.id}`"
                      :class="{ active: selectSmallLinkGroup == names.TRAJECTORIES }"
                  >
                    <div class="navbar-sec__link-container">
                      <img src="@/assets/images/icons/dropdownArrow.svg" alt=""/>
                      <span class="navbar-sec__link-subgroup-name">{{ group.name }}</span>
                    </div>
                  </router-link>
                </li>
                <li v-if="getRight('addGroup')">
                  <router-link
                      to="/group/edit"
                      :class="{ active: selectSmallLinkGroup == names.NEWGROUP }"
                  >
                    <div class="navbar-sec__link-container">
                      <span class="navbar-sec__link-subgroup-name">+ группа</span>
                    </div>
                  </router-link>
                </li>
              </ul>
            </transition>
          </li>

          <!--Эталонный профиль-->
          <li class="navbar-sec__link complex-link"
              :class="{ open: openLinkGroup == names.REFERENCES }"
              >
            <div v-if="referenceProfilesCount" class="navbar-sec__link-container" @click="clickOnMenuItem(names.REFERENCES)">
              <img src="@/assets/images/icons/references.svg" alt=""/>
              <div class="navbar-sec__link-block">
                <div>
                  <p class="navbar-sec__link-name">Эталонный профиль</p>
                  <span class="navbar-sec__explanation">Спец. продукт</span>
                </div>
                <span>{{ referenceProfilesCount }}</span>
              </div>
            </div>
            <transition name="slide-down">
              <ul
                  v-if="openSmallLinkGroup === names.REFERENCES && !showSmallMenu"
                  class="navbar-sec__link-group display--flex flex-column"
              >
                <li v-if="referenceProfilesLength.length">
                  <router-link
                      to="/references/profiles"
                      :class="{ active: selectSmallLinkGroup === names.REFERENCESLIST }"
                      class="navbar-sec__link"
                  >
                    <div class="navbar-sec__link-container">
                      <img src="@/assets/images/icons/dropdownArrow.svg" alt=""/>
                      <span class="navbar-sec__link-subgroup-name">Рассчитанные</span>
                    </div>
                  </router-link>
                </li>
                <li>
                  <router-link
                      to="/references/files"
                      :class="{ active: selectSmallLinkGroup === names.REFERENCESFILES }"
                      class="navbar-sec__link"
                  >
                    <div class="navbar-sec__link-container">
                      <img src="@/assets/images/icons/dropdownArrow.svg" alt=""/>
                      <span class="navbar-sec__link-subgroup-name">Расчет эталона</span>
                    </div>
                  </router-link>
                </li>
              </ul>
            </transition>
          </li>
          <li>
            <div
                class="navbar-sec__link complex-link"
                :class="{ open: openLinkGroup == names.NEWPRODUCT }"
                @click="clickOnMenuItem(names.NEWPRODUCT)"
            >
              <div class="navbar-sec__link-container" @click="showConsultModal">
                <span class="navbar-sec__link-name">+ заказать новый продукт</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </aside>
  <pop-up-form title="Заказать новый продукт"
               comment="Хочу сделать заказ"
               v-if="openConsultModal"
               @closeModal="openConsultModal = false"/>
</template>

<script>
import {ref} from "@vue/reactivity";
import {computed, onBeforeMount, watch} from "@vue/runtime-core";
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import useCheckRights from "./../../mixins/useCheckRights";
import headerDictionary from "./headerDictionary";
import PopUpForm from "../Popups/PopUpForm.vue";

export default {
  name: "header-sec",
  components: {PopUpForm},
  props: {
    updatedGroup: [String, Number],
  },
  setup(props) {
    const store = useStore(),
        route = useRoute(),
        router = useRouter(),
        {getRight} = useCheckRights();

    const openLinkGroup = ref(0),
        tabletBtn = ref(false),
        openSmallLinkGroup = ref(0),
        selectSmallLinkGroup = ref(''),
        currentReportLink = ref(""),
        currentCourseLink = ref(""),
        currentPassing = ref([]),
        currentCourse = ref([]),
        fullCount = ref(null),
        standardCount = ref(null),
        emptyCount = ref(null),
        groups = ref([]),
        coursePassings = ref([]),
        referenceProfilesCount = ref(null),
        trajectoriesCount = ref(null),
        testPassings = ref([]),
        openConsultModal = ref(false)

    const referenceProfilesLength = computed(() => store.state.analytics.referenceProfiles);

    const winWidth = computed(() => store.state.width),
        showXS = computed(() => winWidth.value <= 468),
        courseLink = computed(() => store.state.courseLink),
        simulatorsLink = computed(() => store.state.simulatorsLink),
        isExternal = computed(() =>
            store.state.user && store.state.user.externalEnter
                ? store.state.user.externalEnter
                : false
        ),
        userQuotas = computed(() => store.state.company.userQuotas);

    const isFinishedProgress = computed(() => store.state.isFinishedProgress);
    const passings = computed(() => store.state.test.passings);

    const names = headerDictionary;
    const siteUrl = process.env.VUE_APP_SITE;

    const showSmallMenu = computed(() => {
      if (
          ((winWidth.value < 768 && !tabletBtn.value) ||
              (winWidth.value >= 768 && tabletBtn.value)) &&
          winWidth.value > 468
      ) {
        if (document.querySelector(".page-container")) {
          document.querySelector(".page-container").style.paddingLeft = "30px";
        }

        return true;
      } else {
        if (document.querySelector(".page-container") && winWidth.value > 768) {
          document.querySelector(".page-container").style.paddingLeft = "300px";
        }
        return false;
      }
    });
    const partnersLink = process.env.VUE_APP_PARTNERS_URL;
//TODO newSIDEBAR
    onBeforeMount(() => {
      store.dispatch("company/getUserQuotas")
      store.dispatch("getCourseLink");
      store.dispatch("getIspringSimulators");
      store.dispatch("getIsFinishedProgress");
      store.dispatch("test/getPassings");
      store.dispatch("analytics/getReferenceProfiles")
      lightLink();
    });

    function setNewSimulatorsLink() {
      store.dispatch("getIspringSimulators").then(() => {
        window.open(simulatorsLink.value)
      });
    }


    const clickOnMenuItem = (id) => {
      selectSmallLinkGroup.value = '';
      if (showSmallMenu.value) {
        openSmallLinkGroup.value = id;
        tabletBtn.value = !tabletBtn.value;
      } else {
        openSmallLinkGroup.value == id
            ? (openSmallLinkGroup.value = 0)
            : (openSmallLinkGroup.value = id);
      }
    };

    const getReportLink = (test) => {
      if (test.count == 1) {
        currentPassing.value = passings.value.find((passing) => passing.report_type.toString() === test.name)
        window.open(`${process.env.VUE_APP_FRONT}/passing/${currentPassing.value.code}`, "_self")
      } else {
        window.open(`${process.env.VUE_APP_FRONT}/test/passing`, "_self")
      }

    };
    const getCourseLink = (id) => {
      currentCourse.value = courseLink.value.courses.find((course) => course.external_id === id);
      currentCourseLink.value = currentCourse.value.url
      window.open(currentCourseLink.value, "_blank")
    }

    const showConsultModal = () => {
      openConsultModal.value = !openConsultModal.value
    }

    watch(userQuotas, () => {
      fullCount.value = userQuotas.value.test_passings?.full?.total
      standardCount.value = userQuotas.value.test_passings?.standard?.total
      emptyCount.value = userQuotas.value.test_passings?.empty?.total
      groups.value = userQuotas.value.groups
      coursePassings.value = userQuotas.value.course_passings.total
      referenceProfilesCount.value = userQuotas.value.reference_profiles?.count
      trajectoriesCount.value = userQuotas.value.trajectories?.count

      testPassings.value = [
          {
          title: "Тестирование со стандартным отчетом",
          count: standardCount.value,
          name: "standard"
        },
        {
          title: "Тестирование с полным отчетом",
          count: fullCount.value,
          name: "full"
        }
      ];

      if (emptyCount.value > 0) {
        testPassings.value.push({
          title: "Тестирование для исследования",
          count: emptyCount.value,
          name: "empty"
        })
      }
    })

    watch(showXS, () => {
      if (document.querySelector(".page-container")) {
        document.querySelector(".page-container").style.paddingLeft = "20px";
      }
    });

    watch(
        () => route.path,
        () => {
          if (winWidth.value < 768) {
            tabletBtn.value = false;
          }
          lightLink();
        }
    );

    watch(() => props.updatedGroup, () => {
      store.dispatch("company/getUserQuotas");

      if (openSmallLinkGroup.value) {
        router.push('/' + openSmallLinkGroup.value + '/all');
      } else {
        router.push('/')
      }
    });

    const lightLink = () => {
      let pathPartitions = route.path.split('/') ?? [];
      selectSmallLinkGroup.value = pathPartitions[2] ? pathPartitions[2] : '';
    };

    return {
      openLinkGroup,
      showSmallMenu,
      winWidth,
      tabletBtn,
      showXS,
      openSmallLinkGroup,
      clickOnMenuItem,
      selectSmallLinkGroup,
      courseLink,
      lightLink,
      getRight,
      simulatorsLink,
      isExternal,
      partnersLink,
      names,
      isFinishedProgress,
      siteUrl,
      setNewSimulatorsLink,
      standardCount,
      fullCount,
      emptyCount,
      groups,
      coursePassings,
      referenceProfilesCount,
      referenceProfilesLength,
      trajectoriesCount,
      getReportLink,
      currentReportLink,
      getCourseLink,
      currentCourseLink,
      testPassings,
      showConsultModal,
      openConsultModal
    };
  },
};
</script>

<style lang="scss" scoped>
.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
}

aside {
  z-index: 5;
  background-color: #fff;
  padding: 0 0 70px;
  width: 282px;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.3s ease;

  &.aside__small {
    width: 0;
  }

  &.aside__xs {
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  &.open.aside__xs {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }

  .aside-header {
    height: 62.5px;
    padding-left: 16px;
    background-color: #fff;
    position: absolute;
    left: 0;
    top: 0;
    width: 265px;
    transition: width 0.3s ease 0.001s;
  }

  ul li {
    position: relative;
  }

  @media (max-width: 425px) {
    .aside-header {
      padding-left: 2px;
      background-color: transparent;
      width: 210px;
    }
  }
}

.brand {
  height: 100%;

  &:focus {
    background-color: transparent;
  }

  img {
    height: 56px;
    padding: 20px 17px 7px 35px;
  }
}

.xs-menu {
  top: 0;
  left: 0;
  z-index: 5;

  .brand img {
    padding: 20px 17px 7px 15px;
  }
}

.mobile-btn {
  height: 50px;
  width: 50px;
  margin-left: 5px;
  cursor: pointer;

  &__icon {
    width: 20px;
    background-color: var(--main-color);
    height: 1px;
    position: relative;

    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 0;
      width: 20px;
      background-color: var(--main-color);
      height: 1px;
    }

    &::before {
      top: -7px;
    }

    &::after {
      top: 8px;
    }
  }

  &__xs &__icon {
    background-color: #fff;

    &::before,
    &::after {
      background-color: #fff;
    }
  }

  &:hover {
    .mobile-btn__icon {
      background-color: var(--hover-color);

      &::before,
      &::after {
        background-color: var(--hover-color);
      }
    }
  }
}

.navbar-sec::-webkit-scrollbar {
  width: 3px;
  height: 10px;
  background-color: #bbbbbb;
}

.navbar-sec::-webkit-scrollbar-thumb {
  background-color: #1a523b;
  border-radius: 9em;
}

.navbar-sec {
  margin-top: 63px;
  padding-top: 40px;
  padding-bottom: 40px;
  max-height: 100%;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &__icon-container {
    width: 24px;
    align-items: center;
    justify-content: center;
  }

  .small-text {
    font-size: 17px;
  }

  &__link-container {
    display: flex;
    align-items: start;
    border-bottom: 1px solid #E6E6E6;
    width: 100%;
    padding: 10px 0;
  }

  &__link-block {
    padding: 4px;
    display: flex;
    justify-content: space-between;
    width: 100%
  }

  &__link-name {
    font-size: 16px;
    font-weight: 600;
    line-height: 21.79px;
  }

  &__link-subgroup-name {
    padding: 4px;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
  }

  &__explanation {
    font-size: 12px;
    line-height: 16.34px;
    background-color: #3D3D3D;
    border-radius: 5px;
    color: #F4F4F4;
    width: 60%;
    text-align: center;
  }

  &__link {
    min-width: 120px;
    padding: 0 32px;
    color: var(--dark-color);
    -webkit-transition: color 0.12s ease-in-out;
    transition: color 0.12s ease-in-out;
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-direction: column;

    i {
      margin-right: 15px;
    }

    &:hover {
      background-color: transparent;
      color: var(--hover-color);
    }

    &.green {
      background-color: #00e287;
      border-top: 3px solid white;
      border-bottom: 3px solid white;
    }
  }

  &__b-text {
    text-align: left;
    line-height: 30px;
    padding: 10px 0;
  }

  &__link.admin {
    background-color: #cde2d9;
  }

  &__link.open {
    color: #fff;
    background-color: var(--main-color);

    .complex-link__arrow {
      color: #fff;
    }
  }

  &__link-group {
    width: 100%;
    background-color: #fff;

    i {
      margin: 0 6px 0 10px;
    }

    a {
      display: block;
      width: 100%;
      height: 100%;
      line-height: 50px;
      font-size: 1.077rem;
      color: var(--dark-color);
    }

    a.highlight {
      border-left: 10px solid var(--hover-color);
    }

    a:hover {
      color: var(--main-color);
    }

    a.active {
      color: white;
      background-color: var(--main-color70);
    }
  }

  &__link-group-small {
    position: absolute;
    width: 170px;
    z-index: 4;
    top: 270px;
    left: 90px;
    margin-left: 0;
    background-color: var(--main-color);
    padding: 10px 0;

    a {
      display: block;
      color: white;
      padding: 10px 12px;
    }

    a:hover,
    a.active {
      background-color: white;
      color: var(--main-color);
    }
  }
}

.search {
  position: absolute;
  top: 48px;
  right: 0;
  margin-top: -21px;
  width: 235px;
  border-bottom: 1px solid var(--main-color);

  input {
    border: 1px solid transparent;
    background-color: var(--main-color);
    color: #fff;
    width: 100%;
    font-size: 1.1rem;
    line-height: 1.538;
    padding: 8px 10px;
    -webkit-transition: border-color 0.3s ease;
    transition: border-color 0.3s ease;

    &:focus {
      border-color: var(--hover-color);
    }

    &::placeholder {
      color: var(--light-color);
    }

    &:-ms-input-placeholder {
      color: var(--light-color);
    }

    &::-ms-input-placeholder {
      color: var(--light-color);
    }
  }

  svg {
    position: absolute;
    color: #fff;
    right: 8px;
    top: 50%;
    margin-top: -12px;
    margin-right: 3px;
  }
}

.course-link {
  font-size: 0.9rem;
  /* Обрезка текста */
  height: 2.5rem;
  -webkit-box-orient: vertical;
  display: block;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  transition: 0.5s;
}


[data-tooltip]::after {
  content: attr(data-tooltip);
  font-size: 0.8rem;
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: -15px;
  background: #2cc185;
  color: #fff;
  padding: 0.8rem;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  pointer-events: none;
  opacity: 0;
  transition: 0.5s;
}

[data-tooltip]:hover::after {
  opacity: 1;
  top: 0;
}


</style>

import axios from "axios";
import {notify} from "@kyvg/vue3-notification";
import rusToLatin from "./../mixins/rusTranslitutil"

export default {
    namespaced: true,

    state: () => ({
        group: {
            group: null,
            childs: null,
        },
        groups: null,
        groupQuotas: {
            course_passings: {free: null, issued: null},
            test_passings: {free: null, issued: null},
            trajectories: {free: null, issued: null}
        },
        excelStaff: [],
        currentGroup: null,
        newStaffForGroup: false,
        newStaffForGroupValue: null,
        productGroup: null,
        attachedReferenceProfiles: [],
        referenceProfileNotification: null,
    }),

    mutations: {
        setGroup(state, data) {
            state.group = data
        },
        setNewGroup(state, data) {
            state.group.group = {...data, users: []}
        },
        setGroups(state, data) {
            state.groups = data
        },
        addGroup(state, data) {
            if (state.groups) state.groups = [...state.groups, data]
        },
        editGroup(state, data) {
            state.groups = state.groups.map((group) => group.id == data.id ? data : group)
        },
        setGroupUsers(state, data) {
            state.group.users = data
        },
        giveStaffPassings(state, data) {
            state.group.group.users = state.group.group.users.map((staff) => {
                if (data.staffIds.includes(staff.id)) {
                    staff.last_test_passing = {status: "new"};
                }
                return staff;
            })
        },
        takeStaffPassings(state, data) {
            state.group.group.users = state.group.group.users.map((staff) => {
                if (data.staffIds.includes(staff.id)) {
                    staff.last_test_passing = null;
                }
                return staff;
            })
        },
        setExcelStaff(state, data) {
            state.excelStaff = data
        },
        setCurrentGroup(state, data) {
            state.currentGroup = data
        },
        setNewStaffForGroup(state, data) {
            state.newStaffForGroup = data
        },
        setNewStaffForGroupValue(state, data) {
            state.newStaffForGroupValue = data
        },
        setAttachedReferenceProfiles(state, data) {
            state.attachedReferenceProfiles = data
        },
        setNotificationSettings(state, data) {
            state.referenceProfileNotification = data
        },
        giveStaffCourses(state, data) {
            const usersWithCourse = state.group.group.users.map((user) => {
                if (!data.staffIds.includes(user.id)) return user;
                else {
                    user.course_passings.push({name: data.name.text, status: "new", external_id: data.course})
                    return user;
                }
            })
            state.group = {group: {...state.group.group, users: usersWithCourse}}
        },
        giveStaffTrajectory(state, data) {
            let trajectoriesFree = state.groupQuotas.trajectories.free.map((trajectories) => {
                return {...trajectories, count: trajectories.count - data.count}
            });
            let trajectoriesIssued = state.groupQuotas.trajectories.issued.map((trajectories) => {
                return {...trajectories, count: +trajectories.count + +data.count}
            })
            state.groupQuotas = {
                ...state.groupQuotas,
                trajectories: {free: trajectoriesFree, issued: trajectoriesIssued}
            };
        },
        takeStaffTrajectory(state, data) {
            let trajectoriesIssued = state.groupQuotas.trajectories.issued.map((trajectories) => {
                return {...trajectories, count: trajectories.count - data.count}
            })
            let trajectoriesFree = state.groupQuotas.trajectories.free.map((trajectories) => {
                return {...trajectories, count: +trajectories.count + +data.count}
            })
            state.groupQuotas = {
                ...state.groupQuotas,
                trajectories: {free: trajectoriesFree, issued: trajectoriesIssued}
            };
        },
        takeStaffCourses(state, data) {
            const usersWithoutCourse = state.group.group.users.map((user) => {
                if (!data.staffIds.includes(user.id)) return user;
                else {
                    user.course_passings = user.course_passings.filter((course) => course.external_id !== data.course)
                    return user;
                }
            })
            state.group = {group: {...state.group.group, users: usersWithoutCourse}}
        },
        setGroupQuotas(state, data) {
            state.groupQuotas = data;
        },
        setProductGroup(state, data) {
            state.productGroup = data;
        },
    },

    actions: {
        async createGroup({commit}, request) {
            await axios.post(`/groups`, request).then(({data}) => {
                commit("setNewGroup", data)
                notify({
                    title: "Данные сохранены",
                    type: "success"
                })
            })
        },
        async getGroup({commit}, id) {
            await axios.get(`/groups/${id}`)
                .then(({data}) => {
                    commit("setGroup", data)
                })

        },
        async getGroups({commit}, company_id) {
            await axios.get(`/groups` + (company_id ? `?company_id=${company_id}` : ''))
                .then(({data}) => {
                    commit("setGroups", data)
                })
        },
        async updateGroup({commit}, request) {
            await axios.put(`/groups/${request.id}`, request.group)
                .then(({data}) => {
                    commit("setGroup", data)
                    notify({
                        title: "Данные сохранены",
                        type: "success"
                    })
                })
        },
        async deleteGroup({commit}, id) {
            await axios.post(`/groups/delete`, {
                group_id : id,
            })
                .then(() => {
                    notify({
                        title: "Группа удалена",
                        type: "success"
                    })
                });
        },
        async updateGroupUsers({commit}, data) {
            await axios.put(`/groups/${data.id}/users`, {users: data.users})
                .then(({data}) => {
                    commit("setGroupUsers", data)
                    notify({
                        title: "Данные сохранены",
                        type: "success"
                    })
                })
        },
        async addUserToGroup({commit}, data) {
            await axios.put(`/groups/${data.groupId}/${data.userId}`)
                .then(() => {
                    notify({
                        title: "Пользователь добавлен к группе",
                        type: "success"
                    })
                })
        },
        async giveStaffPassings({commit}, data) {
            await axios.put(`/groups/${data.id}/passing/test/${data.reportType}/append`, {users: data.staffIds})
                .then(() => {
                    commit("giveStaffPassings", data)
                    notify({
                        type: "success",
                        title: "Тесты были выданы"
                    })
                })
        },
        async allowToViewReport({commit}, data) {
            await axios.put(`/groups/${data.id}/passing/allow_view_report`, {users: data.staffIds}).then(() => {
                notify({
                    type: "success",
                    title: "Ссылки отправлены на email"
                })
            })
        },
        async takeStaffPassings({commit}, data) {
            await axios.put(`/groups/${data.id}/passing/test/remove`, {users: data.staffIds})
                .then(() => {
                    commit("takeStaffPassings", data)
                    notify({
                        type: "success",
                        title: "Операция прошла успешно"
                    })
                })
        },
        async giveStaffCourses({commit}, data) {
            await axios.put(`/groups/${data.id}/passing/course/${data.course}/${data.source}/append`, {users: data.staffIds})
                .then(() => {
                    commit("giveStaffCourses", data)
                    notify({
                        type: "success",
                        title: "Курсы были выданы"
                    })
                })
        },
        async giveStaffTrajectory({commit}, data) {
            await axios.put(`/groups/${data.id}/trajectories/append`, {users: data.staffIds})
                .then(() => {
                    commit("giveStaffTrajectory", data)
                    notify({
                        type: "success",
                        title: "Траектории были выданы"
                    })
                })
        },
        async takeStaffTrajectory({commit}, data) {
            await axios.put(`/groups/${data.id}/trajectories/remove`, {users: data.staffIds})
                .then(() => {
                    commit("takeStaffTrajectory", data)
                    notify({
                        type: "success",
                        title: "Траектории были удалены"
                    })
                })
        },
        async takeStaffCourses({commit}, data) {
            await axios.put(`/groups/${data.id}/passing/course/${data.course}/remove`, {users: data.staffIds})
                .then(() => {
                    commit("takeStaffCourses", data)
                    notify({
                        type: "success",
                        title: "Операция прошла успешно"
                    })
                })
        },
        async loadGroupFromExcel({commit}, data) {
            let request = new FormData()
            request.append("file", data.file)
            if (data.group_id) request.append("group_id", data.group_id)
            if (data.password_change) request.append("password_change", 1)
            await axios.post(`/users/downloads/excel`, request).then(({data}) => {
                commit("setExcelStaff", data)
            })
        },
        async getCurrentGroup({commit}) {
            await axios.get(`/groups/current`)
                .then(({data}) => {
                    commit("setCurrentGroup", data)
                })
        },
        // eslint-disable-next-line no-unused-vars
        async remindAboutTest({commit}, data) {
            await axios.put(`/groups/${data.id}/passing/reminder`, {users: data.staffIds})
                .then(() => {
                    notify({
                        title: "Напоминания отправлены",
                        type: "success"
                    })
                })
        },
        async getGroupQuotas({commit}, id) {
            await axios.get(`/groups/${id}/quota`)
                .then(({data}) => {
                    commit("setGroupQuotas", data)
                })
        },
        async getReportsArchive({commit}, data) {
            await axios.post(`/groups/${data.group}/passing/download_reports`, {passings: data.codes}).then((response) => {
                let link = document.createElement('a');
                link.href = response.data;
                link.target = "_blank";
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
        },
        async getReport({commit}, data) {
            await axios.post(`/groups/passing/download_report`, {passings: data.passing}).then((response) => {
                let link = document.createElement('a');
                link.href = response.data;
                link.target = "_blank";
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
        },
        async getProductGroup({commit}, data) {
            let searchQuery = '';
            if (data.search_data) {
                searchQuery = '?'
                for (const [key, value] of Object.entries(data.search_data)) {
                    if (value && value !== 'Не выбрано' && value !== '0') {
                        searchQuery += `${key}=${value}&`;
                    }
                }
            }

            await axios.get(`groups/${data.id}/${data.product}${(searchQuery)}`)
                .then(({data}) => {
                    commit("setProductGroup", data)
                })
        },
        async getAttachedToGroupReferenceProfiles({commit}, id) {
            await axios.get(`reference_profiles/attached/${id}`)
                .then(({data}) => {
                    commit("setAttachedReferenceProfiles", data)
                })
        },
        async getNotificationSettings({commit}, id) {
            await axios.get(`groups/reference_profiles/get_notification_settings/${id}`)
                .then(({data}) => {
                    commit("setNotificationSettings", data)
                })
        },
        async attachReferenceProfile({commit}, data) {
            await axios.get(`groups/reference_profiles/attach/${data.group_id}/${data.profile_id}`)
                .then(({data}) => {
                    commit("setAttachedReferenceProfiles", data)
                    notify({
                        title: "Профиль прикреплён",
                        type: "success"
                    })
                }).catch((error) => {
                    notify({
                        title: "Ошибка",
                        type: "error"
                    })
                })
        },
        async detachReferenceProfile({commit}, data) {
            await axios.get(`groups/reference_profiles/detach/${data.group_id}/${data.profile_id}`)
                .then(({data}) => {
                    commit("setAttachedReferenceProfiles", data)
                    notify({
                        title: "Профиль откреплён",
                        type: "success"
                    })
                }).catch((error) => {
                    notify({
                        title: "Ошибка",
                        type: "error"
                    })
                })
        },
        async updateNotificationSettings({commit}, data) {
            await axios.post(`/groups/set_reference_profile_notification/${data.group_id}`, {sendNotification: data.sendNotification})
                .then(() => {
                    notify({
                        title: "Найстройки сохранены",
                        type: "success"
                    })
                })
        }
    }
}
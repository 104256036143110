import axios from "axios";
import { notify } from "@kyvg/vue3-notification";

export default {
    namespaced: true,

    state: () => ({
        diarySettings: null,
        diaryRecords: [],
        currentDayRecords: [],
        createdRecord: [],
        diaryAnalytics: null,
        emotionList: [],
        triggerList: [],
    }),

    mutations: {
        setDiarySettings(state, data) {
            state.diarySettings = data;
        },
        setDiaryRecords(state, data) {
            state.diaryRecords = data;
        },
        setCurrentDayRecords(state, data) {
            state.currentDayRecords = data;
        },
        setDiaryAnalytics(state, data) {
            state.diaryAnalytics = data;
        },
        setEmotionsList(state, data) {
            state.emotionList = data;
        },
        setTriggerList(state, data) {
            state.triggerList = data;
        },
        setCreatedRecord(state, data) {
            state.createdRecord = data;
        },
    },

    actions: {
        async getDiarySettings({ commit }) {
            await axios.get(`diary/settings`).then(({ data }) => {
                commit("setDiarySettings", data);
            })
        },
        async setDiarySettings({ commit }, data) {
            await axios.post(`diary/settings`, data).then(({ data }) => {
                commit("setDiarySettings", data);
                notify({
                    title: "Настройки сохранены",
                    type: "success",
                });
            })
        },
        async getRecords({ commit }, data) {
            await axios.get(`diary/records/all`, data).then(({ data }) => {
                commit("setDiaryRecords", data);
            })
        },
        async getRecordsByDate({ commit }, data) {
            await axios.get(`diary/records?record_date=${data.date}`, data).then(({ data }) => {
                commit("setCurrentDayRecords", data);
            })
        },
        async createRecord({ commit }, data) {
            await axios.post(`diary/records`, data).then((response) => {
                commit("setCreatedRecord", response.data);
            });
        },
        async getEmotionList({ commit }) {
            await axios.get(`diary/emotions`).then(({ data }) => {
                commit("setEmotionsList", data);
            })
        },
        async getTriggerList({ commit }) {
            await axios.get(`diary/triggers`).then(({ data }) => {
                commit("setTriggerList", data);
            })
        },
        async getAnalytics({ commit }, data) {
            await axios.get(`diary/analytics?start_date=${data.start_date}&end_date=${data.end_date}`).then(({ data }) => {
                commit("setDiaryAnalytics", data);
            })
        },
    }
}
